import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useSelector  , useDispatch} from 'react-redux';

// import Config from "../config"
import { Encryptdata, Decryptdata } from "./encode_decode";
import React, {
  useState,

} from "react";
import { toast } from "react-hot-toast";


export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0) ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0);



export const copydata = (data) => {
  var copyText = data;
  console.log("COPYDATA", data);
  navigator?.clipboard?.writeText(copyText);
  return copyText;
};


function getFileExtension(filename) {
  return filename?.split('.').pop();
}

export const AppenData = (data) => {
  
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map((data) => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], Encryptdata(data))

              }
              return formdata
          })
          return come

      }
      else {
      
          if (  getFileExtension(item[1]?.name) == 'glb' || (item[1]?.type && item[1]?.size)) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], Encryptdata(item[1]))

          }
          return formdata
      }
  })
  return SendDta
}


export const axiosFunc = async (data) => {
  try {
    let Resp = await axios(data);
    console.log("recievindata",Resp)

    return Resp;
  } catch (e) {
    console.log("recievindataeeeerrr",e)
    
    return  { status : false ,  message : "unauthorized"}
  }
};
