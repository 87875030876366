import React, { useEffect, useMemo, useState } from "react";
import cascading from "../assests/css/all.module.css";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Sidebar from "../common/Sidebar";
import { Col, Nav, Row, Tab, Form, Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Registervalidation } from "../actions/validations/register_validation";
import { isEmpty } from "../actions/common";
import { userregister, verifyotp } from "../api/user.axios";
import { Router } from "react-router-dom";
import { Veriftyotp } from "./verifyotp";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import { Decryptdata } from "../actions/encode_decode";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

function Login() {
  //   const router = Router();
  const [otpbox, setotpbox] = useState(false);
  const [errorvalue, seterror] = useState({});
  const [button, setbutton] = useState(false);
  const [phraseshow, setPhraseyshow] = useState(false)
  const [phraseshow1, setPhraseyshow1] = useState(false)
  // const {ref} = useParams();
  const options = useMemo(() => countryList().getData(), [])
  console.log('optionsoptionsoptionsoptions-->',options)
  const loc = useLocation()
  const ref = Decryptdata(loc.search.split('=')[1])
  console.log('ref-->',loc,loc.search.split('=')[1])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const initialdata = {
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    password: "",
    repassword: "",
    defaultcheckbox: false,
    refferalcode: ref ?? "",
    region : 'in'
  };
  const [formdata, setformdata] = useState(initialdata);
console.log('formdata-->',formdata)
  const updatedata = (e, key) => {
    console.log('formdata-->formdata-->-->',e,e.target,key,isEmpty(key?.countryCode))
    if (key) {
      setformdata({ ...formdata, [ isEmpty(key?.countryCode) ? key?.name : 'phonenumber' ] : isEmpty(key?.countryCode) ? e.label.toLowerCase() : e });
    } else {
      seterror({});
      // setformdata("");
      setbutton(false);
      const { id, value, checked } = e.target;
      if (id == "defaultcheckbox") {
        setformdata({ ...formdata, [id]: checked });
      } else {
        setformdata({ ...formdata, [id]: value });
      }
    }
  };

  const submit = async () => {
    const toastId = toast.loading("please wait...");
    try {
      const RegisterSchema = Yup.object({
        firstname: Yup.string().required("Firstname can't be empty"),
        lastname: Yup.string().required("Lastname can't be empty"),
        password: Yup.string()
          .required()
          .min(6, "Password must be at least 6 characters")
          .matches(
            /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
            "Password must contain at least one symbol, one number, one uppercase letter, and one lowercase letter"
          ),
        repassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match"
        ),
        phonenumber: Yup.string().matches(
          /^(\+)?[0-9\s-]{6,15}$/,
          "Enter valid phone number"
        ),
        email: Yup.string()
          .email("Enter valid email address")
          .required("Email is required"),
        defaultcheckbox: Yup.boolean()
          .oneOf([true], "Accept the Terms of Service to create an account")
          .required(),
          region : Yup.string()
        // refferalcode : Yup.optional()
      });

      await RegisterSchema.validate(formdata, { abortEarly: false });

      let data = {
        refferalcode: formdata?.refferalcode,
        firstname: formdata?.firstname,
        lastname: formdata?.lastname,
        password: formdata?.password,
        confirmpassword: formdata?.repassword,
        phone: formdata?.phonenumber,
        email: formdata?.email,
        termsandserviceaccept: true,
        refferalcode: formdata?.refferalcode ? formdata?.refferalcode : "",
        region : formdata?.region
      };

      const result = await userregister(data);

      toast.dismiss(toastId);
      if (result?.status) {
        toast.success(result?.message);
        //   setTimeout(() => {
        //     router.push({
        //         pathname:'/verification',
        //         state : {email :formdata?.email }
        //     });
        //   }, 1000);
        setotpbox(true);
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("validation error");
      console.error(error);
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      seterror(newErrors);
    } finally {
    }

    // if (!isEmpty(errors)) {
    //   setbutton(true);
    //   seterror(errors);
    //   return;
    // }
  };

  return !otpbox ? (
    <div className={`${cascading.loginborder}`}>
      <div className={`container ${cascading.custom_container}`}>
        <Row>
          <Col sm={9} md={10} xl={8} className={`mx-auto`}>
            <div className={`${cascading.logincard}`}>
              <h2 className={`${cascading.whtclr} mb-4`}>Registration</h2>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className={`${cascading.whtclr}`}>
                      First name
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="text"
                      id="firstname"
                      onChange={updatedata}
                      value={formdata?.firstname}
                      placeholder="Enter your First name"
                    />
                    {errorvalue?.firstname && (
                      <span class="text-danger">{errorvalue?.firstname}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className={`${cascading.whtclr}`}>
                      Last name
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      id="lastname"
                      type="email"
                      value={formdata?.lastname}
                      onChange={updatedata}
                      placeholder="Enter your Last name"
                      autocomplete="off"
                    />
                    {errorvalue?.lastname && (
                      <span class="text-danger">{errorvalue?.lastname}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Email address
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      id="email"
                      type="test"
                      value={formdata?.email}
                      onChange={updatedata}
                      placeholder="Enter your Email address"
                      autocomplete="off"
                    />
                    {errorvalue?.email && (
                      <span class="text-danger">{errorvalue?.email}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className={`${cascading.whtclr}`}>
                      Phone Number
                    </Form.Label>
                    <PhoneInput
                      country={"us"}
                      id="phonenumber"
                      name='phonenumber'
                      onChange={
                        // (e) => 
                        // updatedata(e, true)
                        updatedata
                      }
                    />
                    {/* <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="text"
                      id="phonenumber"
                      value={formdata?.phonenumber}
                      onChange={updatedata}
                      placeholder="Enter your Phone number"
                    /> */}
                    {errorvalue?.phonenumber && (
                      <span class="text-danger">{errorvalue?.phonenumber}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Password
                    </Form.Label>
                    <div className="d-flex">
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      value={formdata?.password}
                      id="password"
                      type={phraseshow ? 'text' : "password"}
                      onChange={updatedata}
                      placeholder="Enter your Password"
                    ></Form.Control>
                    {errorvalue?.password && (
                      <span class="text-danger">{errorvalue?.password}</span>
                    )}
                     <div className={`input-group-append`} onClick={() => setPhraseyshow(!phraseshow)}>
                                    <span className={`input-group-text ${cascading.h40 }`} >{phraseshow ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div>
                  </div>
                  </Form.Group>
                 
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Re-enter Password
                    </Form.Label>
                    <div className="d-flex">
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      id="repassword"
                      type={phraseshow1 ? 'text' : "password"}
                      value={formdata?.repassword}
                      onChange={updatedata}
                      placeholder="Re-enter Password"
                    ></Form.Control>
                    {errorvalue?.repassword && (
                      <span class="text-danger">{errorvalue?.repassword}</span>
                    )}
                    <div className={`input-group-append`} onClick={() => setPhraseyshow1(!phraseshow1)}>
                                    <span className={`input-group-text ${cascading.h40 }`} >{phraseshow1 ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div>
                                </div>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Refferal Code
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      id="refferalcode"
                      type="text"
                      value={formdata?.refferalcode}
                      onChange={updatedata}
                      placeholder="Enter refferalcode"
                      disabled={ref ? true : false}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Region
                    </Form.Label>
                    <Select id="region" options={options} name='region' onChange={updatedata} />
                  </Form.Group>
                </Col>


                <Col md={12}>
                  <Form.Check
                    onChange={updatedata}
                    type="checkbox"
                    id={`defaultcheckbox`}
                    label={`I accept the Terms of services`}
                  />

                  {errorvalue?.defaultcheckbox && (
                    <span class="text-danger">
                      {errorvalue?.defaultcheckbox}
                    </span>
                  )}
                </Col>
                <div className={`text-end mt-2`}>
                  {/* <Link to="/login" > */}
                  <Button
                    className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`}
                    onClick={submit}
                    disabled={button}
                  >
                    Create Account
                  </Button>
                  {/* </Link> */}
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <Link className={`${cascading.lightyclr}`} to="/login">
                    Login
                  </Link>
                  {/* <Link className={`${cascading.lightyclr}`} to="/" >Sign up for free</Link> */}
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Veriftyotp email={formdata?.email} />
  );
}

export default Login;
